<template>
  <div class="w-full px-basic my-basic" :class="[parentClass]">
    <div class="mx-auto max-w-screen-xl" :class="nestedClass">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
defineProps({
  parentClass: {
    type: String,
    default: '',
  },
  nestedClass: {
    type: String,
    default: '',
  },
});
</script>

<style lang="scss" scoped></style>
